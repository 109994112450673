<template>
<div class="page-center padded">
    <h1>How to use the Online Genealogical Index (OGI)</h1>
    <p>
        OGI is a search engine for genealogical data sets found online for England and Wales, indexed by place. If you know the place where your ancestor was born, married, died, or buried, OGI helps find sites that contain records for that place - records that may contain details of your ancestor.
    </p>

    <div class="flex-container flex-responsive">

        <div class="semi-padded flex-no-shrink">

            <h4>Articles</h4>
        
            <section class="w3-border no-select">
                <ul class="w3-ul">
                    <li v-for="doc in docs" :key="doc.filename" 
                        @click="loadDocument(doc.filename)" 
                        class="clickable w3-hover-blue"
                        >
                        {{doc.title}}
                    </li>
                </ul>
            </section>
        </div>


        <div class="side-padded w3-margin-left">
            <article v-html="content"></article>        
        </div>
    </div>


</div>
</template>

<script>
import documentLoader from "@/services/documentLoader.js";

export default {
    data(){return {
        docs:[],
        content:""
    }},
    created(){
        this.loadDocumentList();
    },
    methods:{
        loadDocumentList(){
            let s=this;
            documentLoader.getDocumentList().then(data=>{
                s.$set(s, "docs", data);
            }, ()=>{
                s.$set(s, "docs", []);
            })
        },
        loadDocument(filename){
            let s=this;
            documentLoader.getDocument(filename).then(data=>{
                s.content=data;
            }, ()=>{})
        }
    }

}
</script>

<style scoped>
article >>> img{
    max-width: 98%;
}

article >>> a{
    text-decoration: none;
    color: #428bca;
}
</style>